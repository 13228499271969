import { useState } from 'react';

import {
    Button,
    TextField,
    Modal,
    Box,
    Typography,
    CircularProgress
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { RequestNextApi } from '../../api/Request';
import { getCookie } from '../../utils/cookieHandle';

export default function ChangePasswordModal({ defaultEmail, open, setOpen, handler }) {
    const [state_email, setEmail] = useState(null)
    const email = state_email !== null ? state_email : defaultEmail;
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false)
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: mobile ? "90%" : 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
    };

    const handleClose = () => {
        setLoading(false)
        setErrorMessage(null)
        setOpen(false);
    }

    const handleChangePassword = async () => {
        setLoading(true)
        if (password === password2 && password !== "" && password2 !== "" && email !== "") {
            try {
                const result = await RequestNextApi.post('/user/user-change-password',
                    {
                        email: email,
                        newPassword: password,
                        jwtToken: getCookie('jwt-token')
                    });
                if (result.data.success) {
                    handler();
                } else {
                    if (result.data.message) {
                        setLoading(false)
                        setErrorMessage(result.data.message)
                    }
                }
            }
            catch (error) {
                // 
            }
        } else if (email === "") {
            setLoading(false)
            setErrorMessage("Email cannot be empty.")
        }
        else if (password === "" && password2 === "") {
            setLoading(false)
            setErrorMessage("Password fields cannot be empty.")
        } else {
            setLoading(false)
            setErrorMessage("Passwords do not match.")
        }
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <div className='flex flex-col space-y-3 w-full mt-2'>
                        <Typography className=' text-secondary-500 pb-2.5 pt-2.5 text-center' style={{ fontSize: 20 }}>Change Password</Typography>
                        <Typography className=' text-black-400 pb-2.5 text-center' style={{ fontSize: 16 }}>Use new the password the next time you login.</Typography>
                        <TextField
                            fullWidth
                            className="pb-2.5"
                            label="Email"
                            color="third"
                            size="small"
                            id="email"
                            name="email"
                            value={email}
                            focused
                            type="text"
                            onChange={(e) => {
                                if (errorMessage !== null) { setErrorMessage(null) }
                                setEmail(e.target.value)
                            }}
                        />
                        <TextField
                            fullWidth
                            className="pb-2.5"
                            label="Password"
                            color="third"
                            size="small"
                            id="password"
                            name="password"
                            value={password}
                            type="password"
                            focused
                            onChange={(e) => {
                                if (errorMessage !== null) { setErrorMessage(null) }
                                setPassword(e.target.value)
                            }}
                        />
                        <TextField
                            fullWidth
                            className="pb-2.5"
                            label="Confirm Password"
                            color="third"
                            size="small"
                            id="password"
                            name="password"
                            value={password2}
                            type="password"
                            focused
                            onChange={(e) => {
                                if (errorMessage !== null) { setErrorMessage(null) }
                                setPassword2(e.target.value)
                            }}
                        />
                        {loading ?
                            <Button
                                variant="contained"
                                className='bg-secondary-500 hover:bg-secondary-500 mt-3 w-1/3 self-center whitespace-nowrap'>
                                <CircularProgress size={24} sx={{ color: "white" }} />
                            </Button>
                            :
                            <Button
                                variant="contained"
                                className='bg-secondary-500 hover:bg-secondary-500 mt-3 w-auto lg:w-1/3 self-center whitespace-nowrap'
                                onClick={handleChangePassword}>
                                Change Password
                            </Button>
                        }
                        {errorMessage != null ?
                            <Typography className=' pb-2.5 pt-2.5 text-center' style={{ fontSize: 16, color: "red" }}>{errorMessage}</Typography>
                            :
                            <></>
                        }
                    </div>
                </Box>
            </Modal>
        </>
    )
}
