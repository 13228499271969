import { useState } from 'react'
import { useRouter } from "next/router";

import Cookies from 'js-cookie';

import {
    Button,
    TextField,
    Modal,
    Box,
    Typography
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { RequestNextApi } from '../../api/Request';
import { ShowNotification } from '../../utils/notification/snackbarUtils';

export default function CreateAccountModal({updateProgress, fetchUser, setIsLoggedIn, setAddNewCard, setUserValid, View}) {
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("")
    const [errorMessage, setErrorMessage] = useState(null)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const router = useRouter();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: mobile?"90%":500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
    };

    const handleSignUp = async () => {
        if(setUserValid){setUserValid(false);}
        if(setAddNewCard){setAddNewCard(false);}
        updateProgress({ isLoading: true });
        try {
            const result = await RequestNextApi.post('/user/create',
                {
                    fields: {
                        email: email,
                        password: password,
                        password2: password2,
                        firstName: firstName,
                        lastName: lastName
                    }
                }
            );
            if (result.data.success) {
                Cookies.set('jwt-token', result.data.jwtToken);
                Cookies.set('jwt-refresh-token', result.data.refreshToken, { expires: 3 });
                if(View === "login"){
                    const { query } = router;
                    if (query.returnUrl) {
                        router.push(query.returnUrl);
                    } else {
                        router.push('/members/home');
                    }
                } else if(View === "annual-conference") {
                    if(setIsLoggedIn){setIsLoggedIn(true);}
                    if(fetchUser){await fetchUser();}
                    if(setUserValid) {setUserValid(true);}
                }
            } else {
                if (result.data.message) {
                    setErrorMessage(result.data.message)
                }
                ShowNotification.error('Signup failed!');
            }

        } catch (error) {
            ShowNotification.error('Signup failed!');
        }
        updateProgress({ isLoading: false });
    };
    const lockButton = firstName !== "" && lastName !== "" && email !== "" && password !== "" && password2 !== "" ? false : true

    return (
        <>
            <div className={`mt-3 ${View==="login"? "text-sm text-gray-900":"text-xl"} `}>
                No Kingdom Advisors account?&nbsp;
                <Button
                    disableRipple={true}
                    disableFocusRipple={true}
                    disableElevation={true}
                    sx={{ textTransform: "none", "&:hover": { backgroundColor: "transparent" },padding:0 }}
                    onClick={handleOpen}>
                    <Typography  
                    className={View==="login"? " text-secondary-500":"text-secondary-500 text-xl" } >
                        Create one now
                    </Typography>
                </Button>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <div className='flex flex-col space-y-3 w-full mt-2'>
                        <Typography className=' text-secondary-500 pb-2.5 pt-2.5 text-center' style={{ fontSize: 20 }}>Create an Account</Typography>
                        <div className="flex flex-row items-center w-full pb-2.5"  >
                            <TextField
                                fullWidth
                                label="First Name"
                                color="third"
                                size="small"
                                id="First Name"
                                name="First Name"
                                value={firstName}
                                onChange={(e) => {
                                    if (errorMessage !== null) { setErrorMessage(null) }
                                    setFirstName(e.target.value)
                                }}
                            />
                            <div style={{ paddingLeft: 10 }} />
                            <TextField
                                fullWidth
                                label="Last Name"
                                color="third"
                                size="small"
                                id="Last Name"
                                name="Last Name"
                                value={lastName}
                                onChange={(e) => {
                                    if (errorMessage !== null) { setErrorMessage(null) }
                                    setLastName(e.target.value)
                                }
                                }
                            />
                        </div>
                        <TextField
                            fullWidth
                            className="pb-2.5"
                            label="Email"
                            color="third"
                            size="small"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => {
                                if (errorMessage !== null) { setErrorMessage(null) }
                                setEmail(e.target.value)
                            }
                            }
                        />
                        <TextField
                            fullWidth
                            className="pb-2.5"
                            label="Password"
                            color="third"
                            size="small"
                            id="password"
                            name="password"
                            value={password}
                            type="password"
                            onChange={(e) => {
                                if (errorMessage !== null) { setErrorMessage(null) }
                                setPassword(e.target.value)
                            }
                            }
                        />
                        <TextField
                            fullWidth
                            className="pb-2.5"
                            label="Confirm Password"
                            color="third"
                            size="small"
                            id="password"
                            name="password"
                            value={password2}
                            type="password"
                            onChange={(e) => {
                                if (errorMessage !== null) { setErrorMessage(null) }
                                setPassword2(e.target.value)
                            }}
                        />
                        <Button
                            disabled={lockButton}
                            variant="contained"
                            className='bg-secondary-500 hover:bg-secondary-500 mt-3 w-auto lg:w-1/3 self-center whitespace-nowrap'
                            onClick={handleSignUp}
                        >
                            Create Account
                        </Button>
                        {errorMessage != null ?
                            <Typography className=' pb-2.5 pt-2.5 text-center' style={{ fontSize: 16, color: "red" }}>{errorMessage}</Typography>
                            :
                            <></>
                        }
                    </div>
                </Box>
            </Modal>
        </>
    )
}
